.no-margin {
  margin: 0;
}

.error-container {
  height: 100%;
}

.error-container.on-search {
  margin-top: 70px;
}

.error-container .live-search-content {
  margin-left: 0;
  margin-top: 90px;
}

.error-page {
  display: flex;
  height: 100%;
  background: #fff;
}

.error-page img {
  width: 380px;
}

.error-page .error-full {
  width: 100%;
  margin-top: 70px;
}

.error-page .align-start {
  align-self: flex-start;
}

.error-page .align-end {
  align-self: flex-end;
}

.error-page .errorx {
  width: 400px;
}

.error-page > div {
  display: flex;
  align-items: center;
}

.error-page .left {
  width: 35%;
  justify-content: center;
  background: #f4f6fb;
  overflow: hidden;
}

.error-page .right {
  width: 65%;
}

.error-page .error-content {
  margin: 0 20% 0 100px;
}

.error-page .logo a {
  text-decoration: none;
  color: #2a3342;
}

.error-page .logo a > span {
  font-weight: bold;
}

.error-page .logo {
  font-size: 32px;
  letter-spacing: 2.4px;
  margin-bottom: 60px;
}

.error-page .title {
  font-size: 48px;
  font-weight: bold;
  color: #2a3343;
}

.error-page .separator {
  width: 80px;
  margin: 20px 0;
  border-bottom: 7px solid #3ab1eb;
}

.error-page .description {
  font-size: 18px;
  font-weight: 300;
}
